import { graphql, Link } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import SEO, { SeoModel } from '../components/seo';
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons';

interface PostNode {
  excerpt: string,
  html: string,
  timeToRead: number,
  frontmatter: {
    author: string,
    title: string,
    date: string,
    source_url: string,
    categories: string[],
  }
}

interface PostTemplateProps {
  location: Location,
  readonly data: {
    markdownRemark: PostNode
  },
  readonly pageContext: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    previous?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next?: any
  }
}

export const postTemplateQuery = graphql`
  query PostTemplateQuery($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      excerpt(pruneLength: 2500)
      html
      timeToRead
      frontmatter {
        author
        title
        date(formatString: "DD MMMM YYYY", locale: "nl-NL")
        source_url
        categories
      }
    }
  }
`;

const PostTemplate: React.FC<PostTemplateProps> = ({
  location,
  data,
  pageContext
}) => {
  const {markdownRemark} = data;
  const {previous, next} = pageContext;

  const seo: SeoModel = {
    title: markdownRemark.frontmatter.title,
    description: markdownRemark.excerpt,
    isArticle: true
  };

  return (
    <Layout location={location}>
      <SEO model={seo} />
      <div className="mt-5" />
      <section id="article" className={`bg-light text-left px-3 py-5`}>
        <div className="container">
          {/* <p>
            <Link to="/archive">Terug naar het nieuwsarchief</Link>
          </p> */}
          <div className="mt-4 mb-5" key={`post-${markdownRemark.excerpt}`}>
            <h3 className="d-flex justify-content-between">
              {markdownRemark.frontmatter.title}
            </h3>
            <p className="text-muted">
              {/* {markdownRemark.frontmatter.author} */}
              {markdownRemark.frontmatter.date}
              {/* {markdownRemark.timeToRead} {markdownRemark.timeToRead === 1 ? 'minuut' : 'minuten'} leestijd. */}
            </p>
            <h5 className="mb-4">
              {markdownRemark.frontmatter.categories.map(category => (
                <Link key={category} className="badge badge-secondary mr-3" to={`/archive/category/${category}`}>
                  {category}
                </Link>
              ))}
            </h5>
            <p hidden={!markdownRemark.frontmatter.source_url}>
              <a href={markdownRemark.frontmatter.source_url} target="_blank" title="Post weergeven bij bron">Bron weergeven.</a>
            </p>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
            />
          </div>
          <div className="d-flex flex-row">
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
            {next && (
              <Link to={next.fields.slug} rel="next" className="ml-auto">
                {next.frontmatter.title} →
              </Link>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default PostTemplate;
